<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">北方华创集团员工心理健康关怀计划</h1>
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        您好！心理健康是我们幸福生活和快乐工作的基石。评估心理健康水平和提升心理健康素养是增强自我认知、优化压力应对、促进人际关系、提升工作学习效率及促进身心健康的重要途径，有助于实现更加健康和幸福的生活状态。
      </div>
      <br />
      <div class="content">
        本次我们设置了心理健康调查问卷，用以了解员工心理健康状况，以便提供必要的帮助和针对性的服务。让我们以开放的心态积极参与，通过科学、客观的评估，发现自身潜力，调整行为方式，共同促进心理健康。
      </div>
      <div class="content">
        本次调研采用匿名形式进行，由第三方专业机构进行信息收集及分析，个体报告结果只能本人查看；团体报告只呈现整体的趋势性数据，测评过程会严格保护您的个人隐私，请放心作答。
      </div>

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  methods: {
    startAssessment() {
      const userId = localStorage.getItem("userId");
      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/huachuang/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/huachuang/answer");
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
}

.intro-container {
  max-width: 720px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1.5em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
.jiacu {
  /* 加粗 */
  font-weight: bold;
}
</style>
